import React, { useState, useEffect } from "react";

export default function ModalPrincipal() {
  const [isOpen, setIsOpen] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    "../images/ahorro_afp.webp",
    "../images/ahorro_afp.webp",
  ];

  useEffect(() => {
    if (images.length > 1) {
      const interval = setInterval(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [images.length]);

  const nextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage(
      (prevImage) => (prevImage - 1 + images.length) % images.length
    );
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div
          id="popup-modal"
          className="fixed z-50 top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
        >
          <div className="relative flex items-center justify-center w-11/12 md:w-3/4 lg:w-1/2 h-3/4 bg-white-50 rounded-lg shadow-lg overflow-hidden">
            <button
              className="absolute top-3 right-3 text-secondary-400 hover:text-gray-900"
              onClick={closeModal}
            >
              ✖
            </button>

            {/* Botón Anterior */}
            {images.length > 1 && (
              <button
                className="absolute left-3 bg-gray-700 text-white p-2 rounded-full hover:bg-gray-900"
                onClick={prevImage}
              >
                ◀
              </button>
            )}

            <div
              className="w-full h-full bg-center bg-contain bg-no-repeat"
              style={{
                backgroundImage: `url(${images[currentImage]})`,
              }}
            ></div>

            {/* Botón Siguiente */}
            {images.length > 1 && (
              <button
                className="absolute right-3 bg-gray-700 text-white p-2 rounded-full hover:bg-gray-900"
                onClick={nextImage}
              >
                ▶
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
